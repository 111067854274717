// @flow
import React from 'react';
import { withToastManager } from 'react-toast-notifications';
// Traduction
import { injectIntl } from 'react-intl';
import DriverGroup from '../../DriverGroup';

class TeslaStars extends React.Component<Props, State> {
  render() {

    return <DriverGroup {...this.props} displayHeaderFooter={true} driver_group="648c71c23fc7c221026d2245"/>
  }
}

export default injectIntl(withToastManager(TeslaStars));
